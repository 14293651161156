import { makeStyles } from "@material-ui/core";

const drawerWidth = 240;

const sideMenuStyles = makeStyles((theme) => ({
  drawer: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    alignItems: "center",
    padding: theme.spacing(0, 2),
    justifyContent: "center",
    fontWeight: "bold",
  },
  listItem: {
    alignItems: "flex-start",
    "& span": {
      marginRight: theme.spacing(1),
    },
  },
  listItemText: {
    textTransform: "capitalize",
  },
  listItemTextActive: {
    fontWeight: "bold",
  },
}));

export default sideMenuStyles;
