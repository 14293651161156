// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAOB6Y6H3z3c-EXz66hEfiV6wbcUBL5z-Y",
  authDomain: "c-and-d-iv.firebaseapp.com",
  projectId: "c-and-d-iv",
  storageBucket: "c-and-d-iv.appspot.com",
  messagingSenderId: "993254260935",
  appId: "1:993254260935:web:42daa6b06a79cfd53afee2",
  measurementId: "G-R25QN3RMS8",
};
let firebaseApp;
// Initialize Firebase
if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(firebaseConfig);
} else {
  firebaseApp = firebase.app(); // if already initialized, use that one
}
// const analytics = getAnalytics(app);
export const db = firebaseApp.firestore();
