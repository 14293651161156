import "../App.css";
import { createRef, useEffect, useState } from "react";
import {
  Fullscreen,
  FullscreenExit,
  Pause,
  PlayArrow,
  Replay10,
  VolumeOff,
  VolumeUp,
} from "@material-ui/icons";
import data from "../data.json";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  CssBaseline,
  Grid,
} from "@material-ui/core";
import SideMenu from "../SideMenu/SideMenu";
import TopBar from "../TopBar/TopBar";
import answerGIF from "../assets/novo/answer.gif";
import callingGIF from "../assets/novo/calling.gif";
import novoVideo from "../assets/novo/videos/novo.mp4";
import actionsBg from "../assets/novo/videos/out.png";
import { db } from "../firebase-config";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import logo from "../assets/logo.png";

function VideoComponent(props) {
  const videoRef = createRef();
  const [previousSection, setPreviousSection] = useState();
  const [playing, setPlaying] = useState(false);
  const [videoData, setVideoData] = useState({});
  const [popup, setPopup] = useState();
  const [calling, setCalling] = useState();
  const [sections, setSections] = useState();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [played, setPlayed] = useState([3]);
  const handleFS = useFullScreenHandle();

  const handleDrawerOpen = () => {
    if (popup) return;
    setDrawerOpen(!drawerOpen);
  };
  const drawerOnClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    setVideoData(props.data);
  }, [props]);

  const init = () => {
    let duration = 0;
    let lastInteractionTime = 0;
    if (videoRef && videoRef.current) {
      duration = videoRef.current.duration;
    }
    let sections = data.interactions.map((interaction) => {
      let time = interaction.time || duration;
      interaction.duration = time - lastInteractionTime;
      lastInteractionTime = interaction.time;
      interaction.disabled = true;
      return interaction;
    });
    setVideoData({ ...videoData, duration, sections });
  };

  const toggleSound = (muted = false) => {
    const x = videoRef.current;
    x.muted = !x.muted;
    setVideoData({ ...videoData, muted: x.muted });
  };

  const toggleVideo = async () => {
    if (popup) return;
    const x = videoRef.current;
    if (x.paused) {
      x.play();
    } else {
      x.pause();
    }
    if (props.member) {
      const emailDoc = await db.collection("novo_mce").doc(props.member).get();
      let emailData = emailDoc.data();
      if (!emailData.actions) emailData.actions = [];
      emailData.actions = [
        ...emailData.actions,
        {
          action: x.paused ? "Pause" : "Play",
          time: Date.now(),
        },
      ];
      db.collection("novo_mce").doc(props.member).set(emailData);
    }
  };

  const seekVideo = (forward = true) => {
    if (videoRef && videoRef.current) {
      let time = videoRef.current.currentTime;
      let currentSection = videoData.interactions.find(
        (interaction) => interaction.active === true
      );
      if (forward) {
        time += 10;
      } else {
        time -= 10;
      }
      if (time < currentSection.start) {
        time = currentSection.start;
      }
      if (currentSection.time && time >= currentSection.time) {
        time = currentSection.time - 1;
      }
      videoRef.current.currentTime = time;
    }
  };

  const goToPreviousOptions = () => {
    if (videoRef && videoRef.current && previousSection) {
      const videoElm = videoRef.current;
      videoElm.currentTime = previousSection.time;
    }
  };

  const replaySection = (section) => {
    setPreviousSection(null);
    setSections();
    if (videoRef && videoRef.current) {
      const videoElm = videoRef.current;
      // if (!section.active && !section.disabled) {
      videoElm.currentTime = section.start;
      videoElm.play();
      // }
      let selectedSection = videoData.interactions.find(
        (interaction) => interaction.title === section.title
      );
      if (selectedSection && selectedSection.sections) {
        const index = selectedSection.actions
          .map((action) => action.html)
          .indexOf(section.title);
        if (index !== -1 && !played.includes(index + 1)) {
          setPlayed([...played, index + 1]);
        }
        console.log(played);
      }
    }
  };

  const timeChange = () => {
    const x = videoRef.current;
    console.log(x.currentTime);
    if (videoData) {
      // setProgress((x.currentTime / x.duration) * 100);
      if (data.interactions) {
        const interactions = data.interactions.map((interaction) => {
          interaction.active =
            x.currentTime >= interaction.start &&
            (x.currentTime < interaction.time || !interaction.time);
          if (interaction.active) {
            interaction.disabled = false;
          }
          if (Math.round(x.currentTime) === Math.round(interaction.time)) {
            if (interaction.actions.length > 0) {
              if (interaction.title === "calling") {
                setCalling(interaction);
                x.pause();
              } else if (interaction.sections) {
                if (played.length >= 5) {
                  // setPopup(interaction);
                  continueVideo(interaction.actions[5]);
                } else {
                  setSections(true);
                  x.pause();
                }
              } else {
                setPopup(interaction);
                x.pause();
              }
            }
          }
          return interaction;
        });
        setVideoData({ ...videoData, interactions });
      }
    }
  };

  const playSection = (id) => {
    if (played.indexOf(id) !== -1) return;
    let currentSection = videoData.interactions.find(
      (interaction) => interaction.active === true
    );
    setPlayed([...played, id]);
    try {
      const action = currentSection.actions[parseInt(id) - 1];
      continueVideo(action);
    } catch (error) {}
  };

  const continueVideo = async (t) => {
    let currentSection = videoData.interactions.find(
      (interaction) => interaction.active === true
    );
    if (currentSection) setPreviousSection(currentSection);
    const x = videoRef.current;
    x.currentTime = parseInt(t.time);
    if (Math.round(x.currentTime) === Math.round(parseInt(t.time))) {
      x.currentTime += 1;
    }
    x.play();
    setPopup();
    setCalling();
    setSections();
    if (props.member) {
      const emailDoc = await db.collection("novo_mce").doc(props.member).get();
      let emailData = emailDoc.data();
      if (!emailData.actions) emailData.actions = [];
      emailData.actions = [
        ...emailData.actions,
        {
          action: "Select",
          section: t,
          time: Date.now(),
        },
      ];
      db.collection("novo_mce").doc(props.member).set(emailData);
    }
  };

  return (
    <FullScreen handle={handleFS}>
      {handleFS.active && (
        <>
          <img className="logo" src={logo} alt="logo" />
          <div
            className="fullscreen-bg"
            style={{
              backgroundImage: `url(${videoData.background_image})`,
            }}
          />
        </>
      )}
      {videoData && (
        <div handle={handleFS} className="backgroundVideo">
          <CssBaseline />
          <ClickAwayListener onClickAway={drawerOnClose}>
            <div>
              <TopBar open={drawerOpen} handleDrawerOpen={handleDrawerOpen} />
              <SideMenu
                sections={videoData.sections}
                goToSection={replaySection}
                open={drawerOpen}
                onClose={drawerOnClose}
              />
            </div>
          </ClickAwayListener>
          <h1>{videoData.title}</h1>
          <video
            ref={videoRef}
            id="video1"
            onLoadedData={init}
            poster={videoData.poster}
            onTimeUpdate={timeChange}
            onPlaying={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            onVolumeChange={() =>
              setVideoData({ ...videoData, muted: videoRef.current.muted })
            }
            playsInline
          >
            <source id="mp4" src={novoVideo} type="video/mp4" />
            <p>Your user agent does not support the HTML5 Video element.</p>
          </video>
          <Box
            className={`videoActionsContainer ${!sections && "hidden"}`}
            sx={{ backgroundImage: `url(${actionsBg})` }}
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon
                onClick={() => playSection(1)}
                className={`first ${
                  played.indexOf(1) !== -1 ? "disabled" : ""
                }`}
                points="0 0,35.2 0,4.6 100, 0 100"
              />
              <polygon
                onClick={() => playSection(2)}
                className={`second ${
                  played.indexOf(2) !== -1 ? "disabled" : ""
                }`}
                points="4.6 100,46.3 100, 35.9 0, 35.2 0"
              />
              <polygon
                onClick={() => playSection(3)}
                className={`third ${
                  played.indexOf(3) !== -1 ? "disabled" : ""
                }`}
                points="46.3 100,52.2 100, 73 0, 35.9 0"
              />
              <polygon
                onClick={() => playSection(4)}
                className={`fourth ${
                  played.indexOf(4) !== -1 ? "disabled" : ""
                }`}
                points="73.2,-1 52.2,100 93,100"
              />
              <polygon
                onClick={() => playSection(5)}
                className={`fifth ${
                  played.indexOf(5) !== -1 ? "disabled" : ""
                }`}
                points="73.4 0,100 0,100 100,93 100"
              />
              Sorry, your browser does not support inline SVG.
            </svg>
          </Box>
          {calling && (
            <Box
              sx={{
                background: `url(${
                  calling.actions.length === 2 ? callingGIF : answerGIF
                })`,
              }}
              className="calling-container"
            >
              {calling.actions.map((t, i) => (
                <button onClick={() => continueVideo(t)} key={i}></button>
              ))}
            </Box>
          )}
          <div className="content toggleOnHover">
            <Box p={2}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => seekVideo(false)}
                    >
                      <Replay10 />
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={toggleVideo}
                    >
                      {(playing && <Pause />) || <PlayArrow />}
                    </Button>
                    {/* {previousSection && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={goToPreviousOptions}
                      >
                        Back to options
                      </Button>
                    )} */}
                  </ButtonGroup>
                </Grid>
                <Grid item>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleFS.active ? handleFS.exit() : handleFS.enter()
                      }
                    >
                      {(handleFS.active && <FullscreenExit />) || (
                        <Fullscreen />
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={toggleSound}
                    >
                      {(videoData.muted && <VolumeOff />) || <VolumeUp />}
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      )}

      {popup && (
        <div className="lightbox">
          {popup.actions.map((t, i) => (
            <div
              className="button longbtns box1"
              onClick={() => continueVideo(t)}
              key={i}
              dangerouslySetInnerHTML={{ __html: t.html }}
            ></div>
          ))}
        </div>
      )}
    </FullScreen>
  );
}

export default VideoComponent;
