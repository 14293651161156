import { AppBar, Button, Toolbar } from "@material-ui/core";
import { Close, Menu } from "@material-ui/icons";
import clsx from "clsx";
import topBarStyles from "./TopBarStyles";

const TopBar = (props) => {
  const classes = topBarStyles();
  return (
    <AppBar
      position="absolute"
      color="default"
      className={clsx(classes.appBar, {
        toggleOnHover: true,
        [classes.appBarShift]: props.open,
      })}
    >
      <Toolbar>
        <div className={classes.logo} />
        <Button
          variant="contained"
          color="primary"
          aria-label="open drawer"
          edge="end"
          onClick={props.handleDrawerOpen}
          // className={clsx(props.open && classes.hide)}
        >
          {(props.open && <Close />) || <Menu />}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
