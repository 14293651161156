import HomePage from "./Pages/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";
import EnglishPage from "./Pages/English";
import ArabicPage from "./Pages/Arabic";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2373b9",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          {/* <Route path="/videos/:id">
            <HomePage />
          </Route>
          <Route path="/english">
            <EnglishPage />
          </Route>
          <Route path="/arabic">
            <ArabicPage />
          </Route> */}
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
