import { makeStyles } from "@material-ui/core";

const drawerWidth = 240;

const topBarStyles = makeStyles((theme) => ({
  appBar: {
    top: 0,
    boxShadow: "none",
    backgroundColor: "transparent",
    transition: theme.transitions.create(["margin", "width", "height"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      top: -62,
      "& .MuiToolbar-root": {
        padding: 0,
      },
    },
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  logo: {
    flexGrow: 1,
    height: 50,
    objectFit: "contain",
    objectPosition: "left",
  },
  hide: {
    display: "none",
  },
}));

export default topBarStyles;
